<template>
  <div class="about"></div>
</template>

<script>
export default {
  name: "Logout",
  mounted() {
    this.$store.dispatch("doLogout").then(() => {
      this.$router.push("login");
    });
  },
};
</script>
